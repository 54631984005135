var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-box"},[_c('div',{staticClass:"content"},[_vm._m(0),_vm._v(" "),_c('nav',{staticClass:"navigation"},[_c('nuxt-link',{attrs:{"to":_vm.$i18n.path('others/policy')}},[_vm._v("\n        Privacy Policy\n      ")]),_vm._v(" "),_c('i',[_vm._v("|")]),_vm._v(" "),_c('nuxt-link',{attrs:{"to":_vm.$i18n.path('others/service')}},[_vm._v("\n        Terms of Service (User Agreement)\n      ")]),_vm._v(" "),_c('i',[_vm._v("|")]),_vm._v(" "),_c('nuxt-link',{attrs:{"to":_vm.$i18n.path('others/instructions')}},[_vm._v("\n        Data Deletion Instructions\n      ")])],1),_vm._v(" "),_c('div',{staticClass:"icon-buttons"},[(_vm.$i18n.locale === 'th')?[_vm._m(1),_vm._v(" "),_vm._m(2),_vm._v(" "),_vm._m(3),_vm._v(" "),_vm._m(4),_vm._v(" "),_vm._m(5),_vm._v(" "),_vm._m(6)]:(_vm.$i18n.locale === 'id')?[_vm._m(7),_vm._v(" "),_vm._m(8),_vm._v(" "),_vm._m(9),_vm._v(" "),_vm._m(10),_vm._v(" "),_vm._m(11),_vm._v(" "),_vm._m(12)]:(_vm.$i18n.locale === 'pt')?[_vm._m(13),_vm._v(" "),_vm._m(14),_vm._v(" "),_vm._m(15),_vm._v(" "),_vm._m(16),_vm._v(" "),_vm._m(17),_vm._v(" "),_vm._m(18)]:(_vm.$i18n.locale === 'ru')?[_vm._m(19),_vm._v(" "),_vm._m(20),_vm._v(" "),_vm._m(21),_vm._v(" "),_vm._m(22)]:[_vm._m(23),_vm._v(" "),_vm._m(24),_vm._v(" "),_vm._m(25),_vm._v(" "),_vm._m(26),_vm._v(" "),_vm._m(27),_vm._v(" "),_vm._m(28),_vm._v(" "),_vm._m(29)]],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"copy-right"},[_c('span',{staticClass:"giant-logo"}),_vm._v(" "),_c('span',{staticClass:"giant-info"},[_vm._v("\n        Copyright © PIProductions.Trademarks belong to their respective owners. All rights Reserved\n      ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"//www.facebook.com/supersusth"}},[_c('img',{attrs:{"src":require("assets/images/midicon/facebook.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"//discord.gg/8NxY5KSCex"}},[_c('img',{attrs:{"src":require("assets/images/midicon/discod.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"//www.tiktok.com/@supersusgame"}},[_c('img',{attrs:{"src":require("assets/images/midicon/tiktok.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"//www.youtube.com/@supersusofficial"}},[_c('img',{attrs:{"src":require("assets/images/midicon/youtube.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"//twitter.com/supersusgame"}},[_c('img',{attrs:{"src":require("assets/images/midicon/tuite.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"//www.tiktok.com/@supersusgame"}},[_c('img',{attrs:{"src":require("assets/images/midicon/ing.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"//www.facebook.com/supersusindo"}},[_c('img',{attrs:{"src":require("assets/images/midicon/facebook.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"//discord.gg/ssio"}},[_c('img',{attrs:{"src":require("assets/images/midicon/discod.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"//www.tiktok.com/@supersusgame"}},[_c('img',{attrs:{"src":require("assets/images/midicon/tiktok.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"//www.youtube.com/@supersusofficial"}},[_c('img',{attrs:{"src":require("assets/images/midicon/youtube.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"//twitter.com/supersusgame"}},[_c('img',{attrs:{"src":require("assets/images/midicon/tuite.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"//instagram.com/supersus_id"}},[_c('img',{attrs:{"src":require("assets/images/midicon/ing.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"//www.facebook.com/supersusbr"}},[_c('img',{attrs:{"src":require("assets/images/midicon/facebook.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"//discord.gg/sslo"}},[_c('img',{attrs:{"src":require("assets/images/midicon/discod.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"//www.tiktok.com/@supersusgame"}},[_c('img',{attrs:{"src":require("assets/images/midicon/tiktok.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"//www.youtube.com/@supersusofficial"}},[_c('img',{attrs:{"src":require("assets/images/midicon/youtube.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"//twitter.com/supersusgame"}},[_c('img',{attrs:{"src":require("assets/images/midicon/tuite.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"//www.instagram.com/supersus_br/"}},[_c('img',{attrs:{"src":require("assets/images/midicon/ing.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"//discord.gg/pRD7QdDP4d"}},[_c('img',{attrs:{"src":require("assets/images/midicon/discod.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"//www.tiktok.com/@supersusgame"}},[_c('img',{attrs:{"src":require("assets/images/midicon/tiktok.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"//www.youtube.com/@supersusofficial"}},[_c('img',{attrs:{"src":require("assets/images/midicon/youtube.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"//vk.com/supersusofficial"}},[_c('img',{attrs:{"src":require("assets/images/midicon/vk.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"//www.facebook.com/supersusgame"}},[_c('img',{attrs:{"src":require("assets/images/midicon/facebook.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"//discord.gg/supersus"}},[_c('img',{attrs:{"src":require("assets/images/midicon/discod.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"//www.tiktok.com/@supersusgame?lang=zh-Hans"}},[_c('img',{attrs:{"src":require("assets/images/midicon/tiktok.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"//www.youtube.com/@supersusofficial?app=desktop"}},[_c('img',{attrs:{"src":require("assets/images/midicon/youtube.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"//twitter.com/supersusgame"}},[_c('img',{attrs:{"src":require("assets/images/midicon/tuite.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"//www.instagram.com/supersus_game/"}},[_c('img',{attrs:{"src":require("assets/images/midicon/ing.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"//vk.com/supersusofficial"}},[_c('img',{attrs:{"src":require("assets/images/midicon/vk.png")}})])
}]

export { render, staticRenderFns }